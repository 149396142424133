import styled from 'styled-components';
import './loadAmplitude';
import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import Button from './components/Button';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import InputPhonePage from './pages/InputPhonePage';
import Ready from './pages/Ready';
import { useAsyncEffect } from './hooks/use-async-effect';
import { fetchUtmMap, UtmKey, getUrlParams, fetchAdequatePixel } from './utils/datautils';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  useAsyncEffect(async () => {
    const source = getUrlParams('utm_source');
    if (source) {
      const utmMap = await fetchUtmMap();
      localStorage.setItem(UtmKey, JSON.stringify(utmMap));
    }
  }, []);

  useEffect(() => {
    const pixelId = fetchAdequatePixel();
    fbq('trackSingle', pixelId, 'PageView');

    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'postpay_painful_v4' });
  }, []);

  const [pageNum, setPageNum] = useState(0);
  const toNextPage = () => {
    setPageNum(prev => prev + 1);
  };

  const [inputWorry, setInputWorry] = useState('');
  const [inputPhone, setInputPhone] = useState('');

  const inputPhoneChangeHandler = event => {
    setInputPhone(prev => event.target.value);
  };

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  const readyStateHandler = () => {
    setPageNum(prev => prev + 1);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {pageNum === 0 && (
          <img
            src={'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/landingpage/jasun_tarot.png'}
            width="100%"
            style={{ maxWidth: '37.2rem', marginTop: '1.7rem', marginBottom: '10rem' }}
            alt="메인"
          />
        )}
        {pageNum === 1 && (
          <InputWorryPage value={inputWorry} onChange={inputWorryChangeHandler} onButtonClick={toNextPage} />
        )}

        {pageNum === 0 && (
          <Button
            id="cta-start-writing"
            width="90%"
            backgroundColor="#E1354C"
            text="고민 얘기하기"
            color="#fff"
            style={{
              position: 'fixed',
              bottom: '0.5rem',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '2rem',
              textAlign: 'center',
              marginTop: '10rem',
              marginBottom: '2.5rem',
            }}
            onClickHandler={() => {
              ttq.track('ViewContent');
              toNextPage();
            }}
          />
        )}
        {pageNum === 2 && (
          <InputPhonePage
            onReady={readyStateHandler}
            value={inputPhone}
            onChange={inputPhoneChangeHandler}
            worry={inputWorry}
          />
        )}
        {pageNum === 3 && <Ready isReserved={pageNum === 7} />}
      </div>
    </>
  );
}

export default App;
